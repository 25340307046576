import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { isBoolean, booleanToString } from '@ten24/slatwallreactlibrary/utils'

// TODO: Migrate to reactstrap accordion
const ProductPagePanels = ({ product = {}, attributeSets = [] }) => {
  const { t } = useTranslation()
  const filteredAttributeSets = attributeSets
    .map(set => {
      return { ...set, attributes: set.attributes.filter(attr => attr.attributeCode in product && product[attr.attributeCode] !== ' ' && booleanToString(product[attr.attributeCode]) !== 'No').sort((a, b) => a.sortOrder - b.sortOrder) }
    })
    .filter(set => set.attributes.length)
  return (
    <div className="accordion mb-4" id="productPanels">
      {filteredAttributeSets.map(set => {
        return (
          <div key={set.attributeSetCode} className="card">
            <div className="card-header">
              <h3 className="accordion-heading">
                <a href={`#${set.attributeSetCode}`} role="button" data-toggle="collapse" aria-expanded="true" aria-controls={set.attributeSetCode}>
                  <i className="far fa-key font-size-lg align-middle mt-n1 mr-2"></i>
                  {set.attributeSetName}
                  <span className="accordion-indicator"></span>
                </a>
              </h3>
            </div>
            <div className="collapse show" id={set.attributeSetCode}>
              <ul className="pdp-attributes card-body list-unstyled m-0 font-size-sm">
                {set.attributes.map(({ attributeName, attributeCode }) => {
                  return (
                    <li key={attributeCode} className="d-flex font-size-sm">
                      <span className="mr-2">{attributeName}:{isBoolean(product[attributeCode])} </span>
                      <span className="text-muted">{isBoolean(product[attributeCode]) ? booleanToString(product[attributeCode]) : product[attributeCode]}</span>
                    </li>
                    )
                })}
              </ul>
            </div>
          </div>
        )
      })}

      <div className="card">
        <div className="card-header">
          <h3 className="accordion-heading">
            <a className="collapsed" href="#questions" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="questions">
              <i className="far fa-question-circle font-size-lg align-middle mt-n1 mr-2"></i>
              {t('frontend.product.questions.heading')}
              <span className="accordion-indicator"></span>
            </a>
          </h3>
        </div>
        <div className="collapse" id="questions">
          <div className="card-body">
            <p>{t('frontend.product.questions.detail')}</p>
            <Link to="/contact">{t('frontend.nav.contact')}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ProductPagePanels }
