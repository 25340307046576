import { useEffect, useState } from 'react'
import { sdkURL, axios } from '@ten24/slatwallreactlibrary/services'
import { isAuthenticated } from '@ten24/slatwallreactlibrary/utils'
let qunity;
const useLivePrice = sku => {
  let [ isFetching, setFetching ] = useState(true)
  const [ livePrice, setLivePrice ] = useState({ livePriceFlag: false,quantityBreakPrice:[{}],salePrice: sku?.salePrice, skuCode: sku.skuCode })
  // let [error, setError] = useState({ isError: false, message: '' })
  const authenticated = isAuthenticated()
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (!authenticated) {
      setFetching(false)
      return
    }
    // console.log('Calling Live Price', sku.skuCode)
    axios({
      method: 'POST',
      withCredentials: true,
      url: `${sdkURL}api/scope/getLivePriceAndSurcharge`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { skuCodes: `${sku.skuCode}` },
    })
      .then(response => {
        // console.log('Retrived Live Price', response)
        if (response.status === 200) {
          return response?.data?.data[ 0 ]
        }
        // setError({ isError: true, message: 'Failed to get Live Price' })
        console.log('Failed to get Live Price')
        return 0
      })
      .then(response => {
        setLivePrice(response)
        setFetching(false)
      })
      .catch(() => {
        // setError({ isError: true, message: 'Failed to get Live Price' })
        setFetching(false)
      })
    return () => {
      source.cancel()
    }
  }, [ sku.skuCode, authenticated ])
  if(livePrice?.livePriceData?.quantityBreak.errordetail=== undefined){
        qunity=livePrice?.livePriceData?.quantityBreak||[{}];
  }
  return {
    quantityBreakPrice:qunity,
    isFetchingLivePrice: isFetching,
    // if ERP didn't return a live price, we can't add this item to cart, as the order-item totle would be `0.00`
    salePrice: livePrice?.salePrice || 0,
    
    surCharge: livePrice?.surchargeFlag ? livePrice.surchargeData.amount : 0,
    validForPurchase: livePrice?.livePriceFlag || !authenticated
  }
}
export { useLivePrice }
