import { useState } from 'react'
import { useSelector } from 'react-redux'
import { SlideNavigation, SwRadioSelect, Overlay, PaymentList, CreditCardPayment, GiftCardPayment } from '@ten24/slatwallreactlibrary/components'
import { eligiblePaymentMethodDetailSelector, orderPayment, getAllOrderPayments } from '@ten24/slatwallreactlibrary/selectors/'
import { useTranslation } from 'react-i18next'
import {TermPayment} from '../../../components/Checkout/Payment/TermPayment'

export const CREDIT_CARD = '444df303dedc6dab69dd7ebcc9b8036a'
export const GIFT_CARD = '50d8cd61009931554764385482347f3a'
export const TERM_PAYMENT = '2c918088783591e3017836350bd21385'
export const CASH_PAYMENT = '2c918084757eecb9017593bff5a6001a'

const PaymentSlide = ({ currentStep }) => {
  const orderRequirementsList = useSelector(state => state.cart.orderRequirementsList)
  const user = useSelector(state => state.userReducer)
  const eligiblePaymentMethodDetails = useSelector(eligiblePaymentMethodDetailSelector)
  const { isFetching,total } = useSelector(state => state.cart)
  const { paymentMethod } = useSelector(orderPayment)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(eligiblePaymentMethodDetails[0]?.value)
  const [paymentMethodOnOrder, setPaymentMethodOnOrder] = useState(false)
  const allPayments = useSelector(getAllOrderPayments)
  let creditStatus='';
  if(user.creditStatus==='C'){
    creditStatus= ((total+Number(user.accountBalance)) > Number(user.creditLimit))?'Over Limit':'Open'
  }
  if(user.creditStatus==='P'){
    creditStatus='Open';
  }
  if(user.creditStatus==='F'){
    creditStatus='On Hold';
  }
  const { t } = useTranslation()
  if (paymentMethod && paymentMethod.paymentMethodID && paymentMethodOnOrder !== paymentMethod.paymentMethodID) {
    setPaymentMethodOnOrder(paymentMethod.paymentMethodID)
    setSelectedPaymentMethod(paymentMethod.paymentMethodID)
  }

  return (
    <Overlay active={isFetching} spinner>
      {/* <!-- Payment Method --> */}
      <PaymentList />
      {allPayments.length === 0 && (
        <>
          <div className="row mb-3">
          <div className="col-sm-12">
          <div className="form-group"><label className="w-100 h6">Credit Terms</label><hr/><br/>
          <div className="d-flex flex-column">
          <div className="form-check form-check-inline custom-control mt-1">
          <label className="control-label"> Account Limit: <strong>{user.creditLimit}</strong></label><br />
           <label className="control-label">Account Balance: <strong>{user.accountBalance}</strong></label><br/>
          <label className="control-label"> Account Status: <strong>{creditStatus}</strong></label>
          </div>
          </div>
          </div>
          </div>
      

            <div className="col-sm-12">
              <SwRadioSelect
                label={t('frontend.checkout.payment.select')}
                options={eligiblePaymentMethodDetails}
                onChange={value => {
                  setSelectedPaymentMethod(value)
                }}
                selectedValue={selectedPaymentMethod?.length > 0 ? selectedPaymentMethod : paymentMethodOnOrder}
              />
            </div>
          </div>
          


          {selectedPaymentMethod === CREDIT_CARD && <CreditCardPayment />}
          {selectedPaymentMethod === GIFT_CARD && <GiftCardPayment />}
          {selectedPaymentMethod === TERM_PAYMENT && <TermPayment method={selectedPaymentMethod} />}
        </>
      )}

      <SlideNavigation currentStep={currentStep} nextActive={!orderRequirementsList.includes('payment')} />
    </Overlay>
  )
}

export { PaymentSlide }
