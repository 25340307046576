import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SWImage,SimpleImage } from '@ten24/slatwallreactlibrary/components'
import { addToCart } from '@ten24/slatwallreactlibrary/actions/'
import { useFormatCurrency } from '@ten24/slatwallreactlibrary/hooks'
import { getProductRoute } from '@ten24/slatwallreactlibrary/selectors/'

const OrderItem = ({ quantity, calculatedQuantityDelivered, sku_skuID, sku_skuCode ,sku_product_productName, images, sku_product_urlTitle, BrandName, isSeries, ProductSeries, calculatedExtendedPriceAfterDiscount, sku_calculatedSkuDefinition, sku_imageFile, price, imageFallbackFileName = '' }) => {
  const [formatCurrency] = useFormatCurrency({})
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const useResizedImage = images && images?.length > 0

  const productRouting = useSelector(getProductRoute)
  return (
    <div className="row border-bottom pb-3 pt-4 mx-1">
      <div className="col-sm-2 col-3 pr-0">
        <Link to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`} className="d-inline-block mx-auto image-width">
          {useResizedImage && <SimpleImage src={images[0]} alt={sku_product_productName} />}
          {!useResizedImage && <SWImage customPath={'custom/assets/images/product/default/'} src={sku_imageFile} alt="Product" fallbackPath={imageFallbackFileName.length ? `${imageFallbackFileName}` : ''} />}{' '}
        </Link>
      </div>

      <div className="col-9 col-sm-10 col-md-4">
        {isSeries && <span className="product-meta d-block font-size-xs pb-1">{ProductSeries}</span>}
        {/* <!--- only show this span if part of a bundled product? ---> */}
        <h3 className="product-title font-size-base mb-2">{sku_skuCode}</h3>
        <h3 className="product-title font-size-base mb-2">
          <Link to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}>{sku_product_productName}</Link>
        </h3>
        {/* <!--- product title ---> */}
        <div className="font-size-sm">
          {BrandName} <span className="text-muted mr-2">{sku_calculatedSkuDefinition}</span>
        </div>
        
        {/* <!--- brand / sku ---> */}
      </div>

      <div className="col-sm-12 col-md-6 mt-2 mt-md-0">
        <div className="row">
          <div className="col-3">
            <div className="product-price">
              <div className="font-size-sm">
                {formatCurrency(price)}
                {/* <span className="text-muted mr-2">{`($${listPrice} list)`}</span> */}
              </div>
              {/* <!--- each / list price ---> */}
            </div>
          </div>

          <div className="col-3">
            <p className="mb-0">
              <span className="text-muted font-size-sm">{t('frontend.cart.qty')} </span>
              <span>{quantity}</span>
            </p>
          </div>
          
          <div className="col-3">
            <p className="mb-0">
              <span className="text-muted font-size-sm">{t('frontend.cart.qty')} </span>
              <span>{calculatedQuantityDelivered}</span>
            </p>
          </div>

          <div className="col-3">
            <div className="text-accent">{formatCurrency(calculatedExtendedPriceAfterDiscount)}</div>
            {/* <!--- total ---> */}
            <button
              onClick={event => {
                event.preventDefault()
                dispatch(addToCart(sku_skuID, quantity))
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              }}
              className="btn btn-outline-secondary mt-2"
            >
              {t('frontend.account.reOrder')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const OrderShipments = ({ shipments }) => {
  const { t } = useTranslation()
  return (
    <div className="order-items mr-3">
      {shipments &&
        shipments.map((shipment, index) => {
          return (
            <div className="shipment mb-5" key={index}>
              <div className="order-tracking bg-lightgray px-3 py-2">
                <div className="row">
                  <div className="col-sm-6">{`${t('frontend.account.order.shipment')} ${index + 1} of ${shipments.length}`}</div>
                  {shipment.trackingNumber && (
                    <div className="col-sm-6 text-right">
                      {`${t('rontend.account.order.tracking_number')}: `}
                      <a href="/#to-shipper" target="_blank">
                        {shipment.trackingNumber}
                      </a>
                    </div>
                  )}
                </div>
              </div>

              <div className="card-header">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <h4 className="mb-0 small">{t('frontend.cart.orderItems')}</h4>
                  </div>
                  <div className="col-sm-12 col-md-6 d-none d-md-block">
                    <div className="row">
                      <div className="col-sm-3">
                        <small>{t('frontend.cart.price')}</small>
                      </div>
                      <div className="col-sm-3">
                        <small>Ordered</small>
                      </div>
                      
                      <div className="col-sm-3">
                        <small>{t('frontend.cart.calculatedQuantityDelivered')}</small>
                      </div>
                      <div className="col-sm-3">
                        <small>{t('frontend.cart.total')}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {shipment.orderItems &&
                shipment.orderItems.map((item, index) => {
                  return <OrderItem key={index} {...item} />
                })}
            </div>
          )
        })}
    </div>
  )
}

export { OrderShipments }
// const OrderItem = ({ quantity,calculatedQuantityDelivered, sku_skuID, sku_product_productName, sku_product_urlTitle, BrandName, isSeries, ProductSeries, calculatedExtendedPriceAfterDiscount, sku_calculatedSkuDefinition, sku_imageFile, price, imageFallbackFileName = '' }) => {
//   const [formatCurrency] = useFormatCurrency({})
//   const dispatch = useDispatch()
//   const { t } = useTranslation()

//   const productRouting = useSelector(getProductRoute)
//   console.log('OrderItem', imageFallbackFileName)
//   return (
//     <div className="d-sm-flex justify-content-between align-items-center my-4 pb-3 border-bottom">
//       <div className="media media-ie-fix d-block d-sm-flex align-items-center text-center text-sm-left">
//         <Link to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`} className="d-inline-block mx-auto mr-sm-4" style={{ width: '10rem' }}>
//           <SWImage customPath={'custom/assets/images/product/default/'} src={sku_imageFile} alt="Product" fallbackPath={imageFallbackFileName.length ? `${imageFallbackFileName}` : ''} />
//         </Link>
//         <div className="media-body pt-2">
//           {isSeries && <span className="product-meta d-block font-size-xs pb-1">{ProductSeries}</span>}
//           {/* <!--- only show this span if part of a bundled product? ---> */}
//           <h3 className="product-title font-size-base mb-2">
//             <Link to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}>{sku_product_productName}</Link>
//           </h3>
//           {/* <!--- product title ---> */}
//           <div className="font-size-sm">
//             {BrandName} <span className="text-muted mr-2">{sku_calculatedSkuDefinition}</span>
//           </div>
//           {/* <!--- brand / sku ---> */}
//           <div className="font-size-sm">
//             {`${formatCurrency(price)} ${t('frontend.core.each')} `}

//             {/* <span className="text-muted mr-2">{`($${listPrice} list)`}</span> */}
//           </div>
//           {/* <!--- each / list price ---> */}
//           <div className="font-size-lg text-accent pt-2">{formatCurrency(calculatedExtendedPriceAfterDiscount)}</div>
//           {/* <!--- total ---> */}
//         </div>
//       </div>
//       <div className="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-left" style={{ width: '9rem' }}>
//         <div className="form-group mb-0">
//           <label className="font-weight-medium">{t('frontend.core.quantity')}: </label>
//           <span>{quantity}</span>
//         </div>
//         <div className="form-group mb-0">
//           <label className="font-weight-medium">QTY Shipped : </label>
//           <span>{calculatedQuantityDelivered}</span>
//         </div>
//         <button
//           onClick={event => {
//             event.preventDefault()
//             dispatch(addToCart(sku_skuID, quantity))
//             window.scrollTo({
//               top: 0,
//               behavior: 'smooth',
//             })
//           }}
//           className="btn btn-outline-secondary"
//         >
//           Re-order
//         </button>
//       </div>
//     </div>
//   )
// }

// const OrderShipments = ({ shipments }) => {
//   const { t } = useTranslation()
//   return (
//     <div className="order-items mr-3">
//       {shipments &&
//         shipments.map((shipment, index) => {
//           return (
//             <div className="shipment mb-5" key={index}>
//               <div className="row order-tracking bg-lightgray p-2">
//                 <div className="col-sm-6">{`${t('frontend.account.order.shipment')} ${index + 1} of ${shipments.length}`}</div>
//                 {shipment.trackingNumber && (
//                   <div className="col-sm-6 text-right">
//                     {`${t('rontend.account.order.tracking_number')}: `}
//                     <a href="/#to-shipper" target="_blank">
//                       {shipment.trackingNumber}
//                     </a>
//                   </div>
//                 )}
//               </div>
//               {shipment.orderItems &&
//                 shipment.orderItems.map((item, index) => {
//                   return <OrderItem key={index} {...item} />
//                 })}
//             </div>
//           )
//         })}
//     </div>
//   )
// }

// export { OrderShipments }