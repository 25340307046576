import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFormatCurrency } from '@ten24/slatwallreactlibrary/hooks/'
import { isAccountVerified , shippingMethodSelector } from '@ten24/slatwallreactlibrary/selectors'
import { isAuthenticated } from '@ten24/slatwallreactlibrary/utils'

const OrderSummary = () => {
  const cart = useSelector(state => state.cart)
  const user = useSelector(state => state.userReducer)
  const { total, taxTotal, subtotal, discountTotal, fulfillmentChargeAfterDiscountTotal } = cart
  let freeShippingRemainingAmmount=0;
  if(user.buyinggroup !== 'FF'){
    freeShippingRemainingAmmount=Number(user.buyinggroup-subtotal);
  }
  
  const isAuthed = isAuthenticated()
  const isVerified = useSelector(isAccountVerified)
  const [formatCurrency] = useFormatCurrency({})
  const { t } = useTranslation()
  return (
    <>
      <div className="widget mb-3">
        <h2 className="widget-title text-center">{t('frontend.order.summary')}</h2>
      </div>
      <ul className="list-unstyled font-size-sm pb-2 border-bottom">
        <li className="d-flex justify-content-between align-items-center">
          <span className="mr-2">{t('frontend.order.subtotal')}:</span>
          <span className="text-right">{isAuthed && isVerified && subtotal > 0 ? formatCurrency(subtotal) : '--'}</span>
        </li>
        <li className="d-flex justify-content-between align-items-center">
          <span className="mr-2">{t('frontend.checkout.summary.shippingMethod')} :</span>
          <span className="text-right">{fulfillmentChargeAfterDiscountTotal > 0 ? formatCurrency(fulfillmentChargeAfterDiscountTotal) : '--'}</span>
        </li>
        <li className="d-flex justify-content-between align-items-center">
          <span className="mr-2">{t('frontend.order.taxes')}:</span>
          <span className="text-right">{taxTotal > 0 ? formatCurrency(taxTotal) : '--'}</span>
        </li>
        <li className="d-flex justify-content-between align-items-center">
          <span className="mr-2">{t('frontend.order.discount')}:</span>
          <span className="text-right text-success font-weight-bold">{discountTotal > 0 ? formatCurrency(discountTotal) : '--'}</span>
        </li>
      </ul>
      <h3 className="font-weight-normal text-center my-4">
        <span>{isAuthed && isVerified && total > 0 ? formatCurrency(total) : '--'}</span>
      </h3>
      <h5 className="font-weight-normal text-center my-4">
        <span>{isAuthed && isVerified && user.buyinggroup && user.buyinggroup !== 'FF' && (freeShippingRemainingAmmount > 0 && subtotal>0 )  ? <strong>Add <span className="text-danger font-weight-bold">{formatCurrency(freeShippingRemainingAmmount) }</span> more to reach Free Shipping!</strong>: ''}</span>
      </h5>
    </>
  )
}

export { OrderSummary }
