import { default as preload } from '@ten24/slatwallreactlibrary/src/preload'

// CAUTION: when overriding core configs here, 
// make sure that you're spreading nested objects from core config as well
// because spreading only makes shallow copies
const data = {
  ...preload,
  site: { ...preload.site, siteName: 'Stone & Berg', siteCode: process.env.REACT_APP_SITE_CODE },
  enforceVerifiedAccountFlag: true,
  shopByManufacturer: {
    ...preload.shopByManufacturer,
    slug: '/brands',
    gridSize: 1000,
    maxCount: 1000,
  },
  seo: {
    title: 'Stone & Berg',
    titleMeta: '',
  },
  productPrice: {
    ...preload.productPrice,
    checkInvetory: true,
    showPriceForUnverifiedAccounts: true,
  },
  products: {
    ...preload.products,
    quantityInput: 'text', // [ text|dropdown]
  },
  footer: {
    formLink: 'https://stoneandberg.us3.list-manage.com/subscribe/post?u=8eee6b8b93baf1968074021ef&id=ddc565ac59',
  },
  theme: {
    ...preload.theme,
    host: process.env.REACT_APP_HOST_URL,
    primaryColor: '5f1018',
  },
  formatting: {
    ...preload.formatting,
  },
  analytics: {
    tagManager: {
      gtmId: 'GTM-KMKKPR9',
    },
    googleAnalytics: {
      id: '',
    },
    reportWebVitals: false,
  },
  forms: {
    contact: '2c91808575030b800175064d31680010',
  },
}
export default data
