import Slider from 'react-slick'
import { SWImage } from '@ten24/slatwallreactlibrary/components'
import { useProductDetailGallery } from '@ten24/slatwallreactlibrary/hooks'

/*

Probably should move to this eventually 
https://react-slick.neostack.com/docs/example/custom-paging
*/
const ProductDetailGallery = ({ productID, skuID, imageFile, productDescription }) => {
  const { productImageGallery, sliders, slider1, slider2, filterImages } = useProductDetailGallery({ productID, skuID, imageFile })
  return (
    <div className="col-lg-6 pr-lg-5 pt-0">
      <div className="cz-product-gallery">
        <div className="cz-preview order-sm-2">
          <div className="cz-preview-item active" id="primaryProductImage">
            <div>
              <Slider arrows={false} asNavFor={sliders.nav2} ref={slider => (slider1.current = slider)}>
                {productImageGallery.isLoaded &&
                  filterImages?.map(({ RESIZEDIMAGEPATHS, ORIGINALFILENAME = '' }, index) => {
                    return <SWImage key={`${ORIGINALFILENAME}-${index}`} customPath="/" src={RESIZEDIMAGEPATHS[0]} className="cz-image-zoom w-100 mx-auto" alt="Product" style={{ maxWidth: '500px' }} />
                  })}
              </Slider>
            </div>
            <div className="cz-image-zoom-pane"></div>
          </div>
        </div>
      </div>
      <div className="cz-product-gallery">
        <div className="cz-preview order-sm-2">
          <div className="cz-preview-item active" id="primaryProductImageRow">
            <div>
              {filterImages && filterImages.length > 1 && (
                <Slider arrows={false} infinite={filterImages.length > 3} asNavFor={sliders.nav1} ref={slider => (slider2.current = slider)} slidesToShow={3} swipeToSlide={true} focusOnSelect={true}>
                  {productImageGallery.isLoaded &&
                    filterImages.map(({ RESIZEDIMAGEPATHS, ORIGINALFILENAME = '' }, index) => {
                      return <SWImage key={`${ORIGINALFILENAME}-${index}`} customPath="/" src={RESIZEDIMAGEPATHS[1]} className="cz-image-zoom w-100 mx-auto" alt="Product" style={{ maxWidth: '100px' }} />
                    })}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
       {productDescription && <div className="productDescription" dangerouslySetInnerHTML={{__html: productDescription}}></div>}
    </div>
  )
}
export { ProductDetailGallery }