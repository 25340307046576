import React, { Suspense, useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Loading, SEO, CMSWrapper, ScrollToTop, Header } from '@ten24/slatwallreactlibrary/components'
import { getConfiguration } from '@ten24/slatwallreactlibrary/actions'
import { NotFound, ErrorFallback, Search, Brand, ContentPage, ProductType, Category, OrderConfirmation, Manufacturer, Contact } from '@ten24/slatwallreactlibrary/pages'
import ProductDetail from './pages/ProductDetail/ProductDetail'
import Account from './pages/Account'
import Cart from './pages/Cart'
import Testing from './pages/Testing/Testing'
import Home from './pages/Home/Home'
import Checkout from './pages/Checkout/Checkout'
import logo from './assets/images/logo.svg'
import mobileLogo from './assets/images/logo-mobile.png'
import { ErrorBoundary } from 'react-error-boundary'
import './custom.css'
const pageComponents = {
  Home,
  ErrorFallback,
  Contact,
  Checkout,
  Cart,
  Search,
  ProductDetail,
  NotFound,
  ContentPage,
  Product: ProductDetail,
  ProductType,
  Category,
  Brand,
  Account,
  Manufacturer,
  OrderConfirmation,
}

//https://itnext.io/react-router-transitions-with-lazy-loading-2faa7a1d24a
export default function App() {
  const loc = useLocation()
  const routing = useSelector(state => state.configuration.router)
  const shopByManufacturer = useSelector(state => state.configuration.shopByManufacturer)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getConfiguration())
  }, [dispatch])
  useEffect(() => {
    console.table({ Client: process.env.REACT_APP_VERSION || 'missing', Core: process.env.REACT_APP_CORE_VERSION || 'missing', SDK: process.env.REACT_APP_SDK_VERSION || 'missing' })
  }, [])
  return (
    <Suspense fallback={<Loading />}>
      <Header logo={logo} mobileLogo={mobileLogo} />
      <ErrorBoundary
        key={loc.pathname}
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <ScrollToTop />
        <SEO /> <CMSWrapper />
        <Switch>
          <Route path="/404" component={NotFound} />
          <Route path="/Error" component={ErrorFallback} />
          <Route path="/contact" component={Contact} />
          {routing.length &&
            routing.map(({ URLKey, URLKeyType }, index) => {
              return <Route key={index} path={`/${URLKey}/:id`} component={pageComponents[URLKeyType]} />
            })}
          <Route path="/order-confirmation" component={OrderConfirmation} />
          <Route path={shopByManufacturer.slug} component={Manufacturer} />
          <Route path="/search" component={Search} />
          <Route path="/my-account" component={Account} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/checkout/:id" component={Checkout} />
          <Route path="/MyAccount" component={Account} />
          <Route path="/shopping-cart" component={Cart} />
          {process.env.REACT_APP_IS_QA === 'yes' && <Route path="/testing" component={Testing} />}
          <Route exact path="/" component={Home} />
          <Route path="" component={ContentPage} />
        </Switch>
      </ErrorBoundary>
    </Suspense>
  )
}
