import { useAccountFavorites } from '@ten24/slatwallreactlibrary/hooks'
import { ListingPagination, Grid, AccountLayout } from '@ten24/slatwallreactlibrary/components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
const AccountFavorites = () => {
  const { skuList, start, end, countToDisplay, setPage } = useAccountFavorites()
  const products = skuList.data.slice(start, end).map(sku => {
    return { ...sku, productName: sku.product_productName, productCode: sku.product_productCode, brandUrlTitle: sku.product_brand_urlTitle, brandName: sku.product_brand_brandName, urlTitle: sku.product_urlTitle }
  })
  const { t } = useTranslation()
  return (
    <AccountLayout>
      <div className="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
        <div className="d-flex justify-content-between w-100">&nbsp;</div>
      </div>
      {!skuList.isFetching && products.length === 0 && (
        <>
          <div className="alert alert-info" role="alert">
            {t('frontend.account.favorites.none')}
          </div>
          <hr className="pb-4" />
          <div className="text-sm-left">
            <Link className="btn btn-primary" to="/">
              {t('frontend.homepage')}
            </Link>
          </div>
        </>
      )}
      {products.length > 0 && (
        <>
          <Grid isFetching={skuList.isFetching} products={products} />
          <ListingPagination recordsCount={skuList.data.length} totalPages={Math.ceil(skuList.data.length / countToDisplay)} setPage={setPage} />
        </>
      )}
    </AccountLayout>
  )
}
export { AccountFavorites }