import { useSelector } from 'react-redux'
import { isAuthenticated } from '@ten24/slatwallreactlibrary/utils'
import { useState } from 'react';

// render quantity input on the basis of config
// renders input on text, dropdown on dropdown
function ProductQuantityInput({ setQuantity, quantity, sku, stock = 0, showStock = true, disabled = false }) {
  const minOrderQuantity = Number(sku.skuOrderMinimumQuantity) || 1;
  const maxOrderQuantity = 5000;// Client requested hard 5k limit //Number(sku.skuOrderMaximumQuantity);
  if( quantity < minOrderQuantity ){
    quantity = parseInt( minOrderQuantity );
  }
  const quantityInput = useSelector(state => state.configuration.products.quantityInput) || 'text';
  const showInventory = useSelector(state => state.configuration.products.showInventory) || false;
  const [displayQuantity, setDisplayQuantity] = useState(quantity);
  
  const validateQuantity = ( qty ) => {
    // This is custom funcitonality for S&B.
    // They need skuOrderMinimumQuantity to work like 'order multiples', in that
    // skuOrderMinimumQuantity should also be used as the incremental value for an item
    // if 'up'/'down' are used on the qty box - it should 'step' via the skuOrderMinimumQuantity.
    // If a custom qty is entered via typing - we want to make sure it complies with the
    // skuOrderMinimumQuantity order multiple.
    qty = Math.min( maxOrderQuantity, parseInt( qty ) );
    if( qty >= minOrderQuantity ) { 
      if ( minOrderQuantity === 1 || qty % minOrderQuantity === 0 ) {
        setDisplayQuantity( qty );
        setQuantity( qty );
      } else {
        // scenario:
        //  quantity == 252
        //  minOrderQuantity == 50
        //  quantity / minOrderQuantity == 5.xyz
        //  parseInt( 5.xyz + 1 ) == 6
        //  6 * 50 == 300
        qty =  parseInt( ( ( qty / minOrderQuantity ) + 1 ) * minOrderQuantity );
        setDisplayQuantity( qty );
        setQuantity( qty );
      }
    } else {
      setDisplayQuantity( minOrderQuantity );
      setQuantity( minOrderQuantity );
    }
  }
  
  return (
    <div>
      {quantityInput === 'text' ? (
        <input
          type="number"
          onChange={event => validateQuantity(event.target.value)}
          value={displayQuantity}
          step={minOrderQuantity}
          className="form-control mr-3"
          style={{ width: '5rem' }}
          disabled={disabled}
        />
      ) : (
        <select
          value={displayQuantity}
          onChange={event => setQuantity(event.target.value)}
          className="custom-select mr-3"
          style={{ width: '5rem' }}
          disabled={disabled}
        >
          {sku &&
            sku.calculatedQATS > 0 &&
            [ ...Array(sku.calculatedQATS > 20 ? 20 : sku.calculatedQATS).keys() ].map((value, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
        </select>
      )}

      {showStock && isAuthenticated && showInventory && stock > 0 && (
        <div style={{ marginRight: '5px' }}>
          {parseInt(stock)}<span className='text-accent'> in Stock</span>
        </div>
      )}
    </div>
  )
}

export { ProductQuantityInput }