import { PageHeader, Layout, StepsHeader, getCurrentStep, ShippingSlide } from '@ten24/slatwallreactlibrary/components'
import { ReviewSlide } from '../../components/Checkout/Review/Review'
import { CheckoutSideBar } from '../../components/Checkout/CheckoutSideBar'
import {PaymentSlide} from '../../components/Checkout/Payment/Payment'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import './checkout.css'
import { isAuthenticated } from '@ten24/slatwallreactlibrary/utils'
import { useEffect } from 'react'
import { clearUser, requestLogOut } from '@ten24/slatwallreactlibrary/actions'

const Checkout = () => {
  let match = useRouteMatch()
  const loc = useLocation()
  const history = useHistory()
  const path = loc.pathname.split('/').reverse()[ 0 ].toLowerCase()
  const currentStep = getCurrentStep(path)
  const { verifiedAccountFlag, isFetching, accountID } = useSelector(state => state.userReducer)
  const enforceVerifiedAccountFlag = useSelector(state => state.configuration.enforceVerifiedAccountFlag)
  const dispatch = useDispatch()
  const cartState = useSelector(state => state.cart) // check if there is some change in state , just to run use effect

  useEffect(() => {
    if (!isAuthenticated()) {
      dispatch(clearUser())
      dispatch(requestLogOut())
      history.push(`/my-account?redirect=${loc.pathname}`)
    }
  }, [ history, loc, cartState, dispatch ])

  if (enforceVerifiedAccountFlag && !verifiedAccountFlag && isAuthenticated() && !isFetching && accountID.length > 0) {
    return <Redirect to="/account-verification" />
  }

  return (
    <Layout>
      <PageHeader />
      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <section className="col-lg-8">
            {/* <!-- Steps--> */}
            <StepsHeader />
            <Route path={`${match.path}/cart`}>
              <Redirect to="/cart" />
            </Route>

            <Switch>
              <Route path={`${match.path}/shipping`}>
                <ShippingSlide currentStep={currentStep} />
              </Route>

              <Route path={`${match.path}/payment`}>
                <PaymentSlide currentStep={currentStep} />
              </Route>
              <Route path={`${match.path}/review`}>
                <ReviewSlide currentStep={currentStep} />
              </Route>
              <Route path={match.path}>
                <Redirect to={`${match.path}/shipping`} />
              </Route>
            </Switch>
          </section>
          {/* <!-- Sidebar--> */}
          <CheckoutSideBar />
        </div>
      </div>
    </Layout>
  )
}

export default Checkout
