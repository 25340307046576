import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ContentSlider, ProductSlider, BrandSlider, ContentColumns, Layout } from '@ten24/slatwallreactlibrary/components'
import { useTranslation } from 'react-i18next'

const Home = () => {
  const { t } = useTranslation()

  return (
    
    <Layout>
     <Helmet>
       <meta name="description" content={t('frontend.home.seo.description')} />
      </Helmet>
      <ContentSlider />
      <ProductSlider
        title={t('frontend.home.featured.heading')}
        params={{
          'f:publishedFlag': 1,
          'f:productFeaturedFlag': 1,
        }}
      >
      </ProductSlider>
      <ContentColumns page={'home'} />
      <BrandSlider params={{ 'f:brandFeatured': 1 }} />
    </Layout>
  )
}

export default Home
