import { useTranslation } from 'react-i18next'
import { useFormatCurrency, useProductPrice, useUtilities } from '@ten24/slatwallreactlibrary/hooks'

const ProductPrice = ({ salePrice = 0, listPrice = 0, type = 'product', salePriceSuffixKey, accentSalePrice = true, listPriceSuffixKey = 'frontend.core.list' }) => {
  const [formatCurrency] = useFormatCurrency({})
  const { showSalePrice, showListPrice, showMissingPrice } = useProductPrice({ salePrice, listPrice, type })
  const { isAuthenticated } = useUtilities()
  const { t } = useTranslation()
  const isAuthed = isAuthenticated()
  return (
    <div className="product-price">
      {isAuthed}
      {showMissingPrice && <small>{t('frontend.product.price.missing')}</small>}
      {!showMissingPrice && showSalePrice && (
        <div style={{ marginRight: '5px' }}>
          <span className={accentSalePrice ? 'text-accent font-weight-bold' : ''}>{formatCurrency(salePrice)}</span>
          <small>{salePriceSuffixKey && ` ${t(salePriceSuffixKey)}`}</small>
        </div>
      )}
      {!showMissingPrice && showListPrice && (
        <span>
          <small>
            {`${formatCurrency(listPrice)}`}&nbsp;{t(listPriceSuffixKey)}
          </small>
        </span>
      )}
    </div>
  )
}

export { ProductPrice }
