import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, useRouteMatch, useLocation, Redirect } from 'react-router-dom'
import { getUser } from '@ten24/slatwallreactlibrary/actions'

import { isAuthenticated } from '@ten24/slatwallreactlibrary/utils'
import queryString from 'query-string'
import { Layout, AccountAddresses, UpdatePassword, AccountProfile, AccountPaymentMethods, CreateOrEditAccountAddress, ForgotPassword, AccountCarts, AccountLogin, CreateOrEditAccountPaymentMethod, ForgotPasswordReset } from '@ten24/slatwallreactlibrary/components'
import { AccountOverview } from '../components/Account/AccountOverview/AccountOverview'
import { AccountFavorites } from '../components/Account/AccountFavorites/AccountFavorites'
import { CreateAccount } from '../components/Account/CreateAccount/CreateAccount'
import { AccountOrderHistory } from '../components/Account/AccountOrderHistory/AccountOrderHistory'
import { AccountOrderDetail } from '../components/Account/AccountOrderDetail/AccountOrderDetail'


// eslint-disable-next-line no-unused-vars
const pageComponents = {
  AccountLogin,
  AccountOverview,
  AccountProfile,
  UpdatePassword,
  AccountFavorites,
  AccountAddresses,
  CreateOrEditAccountAddress,
  AccountOrderDetail,
  AccountPaymentMethods,
  AccountOrderHistory,
  CreateOrEditAccountPaymentMethod,
}
const Account = () => {
  let match = useRouteMatch()
  let loc = useLocation()
  const dispatch = useDispatch()
  const user = useSelector(state => state.userReducer)

  useEffect(() => {
    if (isAuthenticated() && !user.isFetching && !user.accountID.length) {
      dispatch(getUser())
    }
  }, [dispatch, user])
  if (isAuthenticated() && loc.search.includes('redirect=')) {
    const params = queryString.parse(loc.search)
    return <Redirect to={params.redirect} />
  }

  const path = loc.pathname.split('/').reverse()
  return (
    <Layout>
      {isAuthenticated() && (
        <Switch>
          <Route path={`${match.path}/addresses/:id`}>
            <CreateOrEditAccountAddress path={path[0]} />
          </Route>
          <Route path={`${match.path}/addresses`}>
            <AccountAddresses />
          </Route>
          <Route path={`${match.path}/cards/:id`}>
            <CreateOrEditAccountPaymentMethod path={path[0]} />
          </Route>
          <Route path={`${match.path}/cards`}>
            <AccountPaymentMethods />
          </Route>
          <Route path={`${match.path}/favorites`}>
            <AccountFavorites />
          </Route>
          <Route path={`${match.path}/orders/:id`}>
            <AccountOrderDetail path={path[0]} forwardState={loc.state} />
          </Route>
          <Route path={`${match.path}/orders`}>
            <AccountOrderHistory />
          </Route>
          <Route path={`${match.path}/profile`}>
            <AccountProfile />
          </Route>
          <Route path={`${match.path}/updatePassword`}>
            <UpdatePassword />
          </Route>
          <Route path={`${match.path}/carts`}>
            <AccountCarts />
          </Route>
          <Route path={match.path}>{isAuthenticated() && <AccountOverview />}</Route>
        </Switch>
      )}
      {!isAuthenticated() && (
        <Switch>
          <Route path={`${match.path}/createAccount`}>
            <CreateAccount />
          </Route>
          <Route path={`${match.path}/forgotPassword`}>
            <ForgotPassword />
          </Route>
          <Route path={`${match.path}/updateForgottenPassword`}>
            <ForgotPasswordReset />
          </Route>
          <Route path={match.path}>
            <AccountLogin />
          </Route>
        </Switch>
      )}
    </Layout>
  )
}

export default Account
