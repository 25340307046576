import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router'
import { BreadCrumb, Layout, RelatedProductsSlider, ProductPageHeader,Spinner } from '@ten24/slatwallreactlibrary/components'
import { useGetEntityByUrlTitleAdvanced, useProductDetail } from '@ten24/slatwallreactlibrary/hooks'
import { disableInteractionSelector, getProductTypeRoute } from '@ten24/slatwallreactlibrary/selectors'
import { ProductPagePanels } from '../../components/ProductDetail/ProductPagePanels'
import { ProductDetails } from '../../components/ProductDetail/ProductDetails'
import { ProductDetailGallery } from '../../components/ProductDetail/ProductDetailGallery'
import { ProductForm } from '../../components/ProductDetail/ProductForm'
import { SkuOptions } from '../../components/ProductDetail/SkuOptions'
const ProductDetail = () => {
  let location = useLocation()
  let history = useHistory()

  const productTypeRoute = useSelector(getProductTypeRoute)
  const productTypeBase = useSelector(state => state.configuration.filtering.productTypeBase)
  const cart = useSelector(disableInteractionSelector)
  const { filterSkusBySelectedOptions, calculateAvaliableOptions, calculateAdditionalParamters } = useProductDetail()

  // selection is an object of current paramters
  // optionGroupPairs is an array of current paramters key=value
  let params = queryString.parse(location.search, { arrayFormat: 'separator', arrayFormatSeparator: ',' })
  let optionGroupPairs = location.search
    .replace('?', '')
    .split('&')
    .filter(param => param.length)
  const urlTitle = location.pathname.split('/').reverse()
  let { isFetching, product, productOptions, skus, error, attributeSets } = useGetEntityByUrlTitleAdvanced(urlTitle[ 0 ])
  if (error.isError) return null
  const selectionToSku = (skus = [], params = []) => {
    const found = skus.filter(sku => {
      return (
        params.filter(code => {
          return sku.slug.includes(code)
        }).length === productOptions.length
      )
    })
    return found.length === 1 ? found[ 0 ] : null
  }
  let selectedSKu = selectionToSku(skus, optionGroupPairs)
  if (params?.skuid) {
    // If we have a skuID we need to redirect to codes
    if (!product) return null
    const found = skus?.filter(sku => sku.skuID === params.skuid)
    if (!found?.length) return null
    if (found.length) {
      history.replace({
        pathname: location.pathname,
        search: found[ 0 ].slug,
      })
    }
  }

  if (optionGroupPairs.length === 0 && product.defaultSku_slug) {
    // This check is for no optionGroupPairs passed
    history.replace({
      pathname: location.pathname,
      search: product.defaultSku_slug,
    })
  }
  //http://localhost:3006/product/demo-product

  const crumbs = product?.data?.breadcrumbs
    ?.map(crumb => {
      return { title: crumb.productTypeName, urlTitle: `/${productTypeRoute}/${crumb.urlTitle}` }
    })
    .filter(crumb => crumb.urlTitle !== `/${productTypeRoute}/${productTypeBase}`)

  const matchingSkus = filterSkusBySelectedOptions(skus, optionGroupPairs)
  const updatedProductOptions = calculateAvaliableOptions(productOptions, params, matchingSkus)
  let updateParams = calculateAdditionalParamters(optionGroupPairs, updatedProductOptions)

  if (updateParams.length) {
    // http://localhost:3006/product/test-product?soccerBallSize=5 ==>  soccerBallColor=green is added
    console.log('Add additional optionGroupPairs because of option matrix')
    history.replace({
      pathname: location.pathname,
      search: [ ...optionGroupPairs, ...updateParams ].join('&'),
    })
  }

  const isDisabled = isFetching || cart.isFetching || !selectedSKu?.skuID
  return (
    <Layout>
      <div className="p-0">
        {product?.productID && (
          <ProductPageHeader title={product?.productSeries}>
            <BreadCrumb crumbs={crumbs} />
          </ProductPageHeader>
        )}

        {product?.productID && <Helmet title={product?.settings?.productHTMLTitleString} />}
        <div className="container bg-light box-shadow-lg rounded-lg px-4 py-3 mb-5">
          {!product?.productID && <Spinner />}
          {product?.productID && (
            <div className="px-lg-3">
              <div className="row">
                <ProductDetailGallery productID={product.productID} skuID={selectedSKu?.skuID} productDescription={product?.productDescription}/>
                
                {/* <!-- Product details--> */}
                <div className="col-lg-6 pt-0">
                  <div className="product-details pb-3">
                    <ProductDetails sku={selectedSKu} product={product} />
                    {!isFetching && !cart.isFetching && skus?.length && <SkuOptions sku={selectedSKu} selection={params} productOptions={updatedProductOptions} skus={skus} />}
                    {selectedSKu && <ProductForm sku={selectedSKu} isDisabled={isDisabled} isLoading={isFetching || cart.isFetching} productUrl={product.urlTitle} />} {/* <!-- Product panels--> */}
                    {/* <!-- Product panels--> */}
                    <ProductPagePanels product={product} attributeSets={attributeSets} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <RelatedProductsSlider productID={product.productID} />
      </div>
    </Layout>
  )
}

export default ProductDetail
