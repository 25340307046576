import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getBrandRoute } from '@ten24/slatwallreactlibrary/selectors'
import { HeartButton } from '@ten24/slatwallreactlibrary/components'

const ProductDetails = ({ sku, product }) => {
  const brand = useSelector(getBrandRoute)
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span className="d-inline-block font-size-sm align-middle px-2 bg-primary text-light"> {product.productClearance === true && ' On Special'}</span>
        {sku && <HeartButton skuID={sku.skuID} className={'btn-wishlist mr-0'} />}
      </div>
      <h2 className="h4 mb-2">{product.productName}</h2>
      <div className="mb-2">
        <span className="text-small text-muted">{`SKU: `}</span>
        {sku && <span className="font-weight-normal text-large text-accent mr-1">{sku.skuCode}</span>}
      </div>
      <div className="mb-2">
        <span className="text-small text-muted">{`Brand: `}</span>
        {product && (
          <span className="font-weight-normal text-large  mr-1">
            <Link to={`/${brand}/${product.brand_urlTitle}`}>{product.brand_brandName}</Link>
          </span>
        )}
      </div>
    </>
  )
}
export { ProductDetails }