import { CartPromoBox, Layout, PromotionalMessaging, AuthenticationStepUp } from '@ten24/slatwallreactlibrary/components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getCart } from '@ten24/slatwallreactlibrary/actions'
import { useEffect } from 'react'
import { PageHeader } from '@ten24/slatwallreactlibrary/components'
import { CartLineItem } from '../components/Cart/CartLineItem.js'
import { disableInteractionSelector } from '@ten24/slatwallreactlibrary/selectors'
import { OrderSummary } from '../components/Checkout/OrderSummary.js'

const Cart = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const disableInteraction = useSelector(disableInteractionSelector)
  const { orderItems } = useSelector(state => state.cart)
  let history = useHistory()
  useEffect(() => {
    dispatch(getCart())
  }, [dispatch])
  return (
    <Layout>
      <PageHeader />

      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <section className="col-lg-8">
            <div className="d-flex justify-content-between align-items-center pt-3 pb-2 pb-sm-5 mt-1">
              <h2 className="h6 mb-0">{t('frontend.cart.heading')}</h2>
            </div>
            <AuthenticationStepUp />
            {orderItems && orderItems.length === 0 && (
              <div className="alert alert-warning" role="alert">
                {t('frontend.cart.empty')}
              </div>
            )}
            {orderItems && orderItems.length > 0 && (
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <h4 className="mb-0 small">{t('frontend.cart.orderItems')}</h4>
                  </div>
                  <div className="col-sm-12 col-md-6 d-none d-md-block">
                    <div className="row">
                      <div className="col-sm-3"><small>{t('frontend.cart.price')}</small></div>
                      <div className="col-sm-4"><small>{t('frontend.cart.qty')}</small></div>
                      <div className="col-sm-3"><small>{t('frontend.cart.total')}</small></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {orderItems &&
              orderItems.map(({ orderItemID }) => {
              return <CartLineItem key={orderItemID} orderItemID={orderItemID} />
            })}
          </section>

          <aside className="col-lg-4 pt-4 pt-lg-0">
            <div className="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto">
              <PromotionalMessaging />

              <OrderSummary />

              <CartPromoBox />

              <button
                className="btn btn-primary btn-block mt-4"
                disabled={disableInteraction}
                onClick={e => {
                  e.preventDefault()
                  history.push('/checkout')
                }}
              >
                {t('frontend.order.to_checkout')}
              </button>
            </div>
          </aside>
        </div>
      </div>
    </Layout>
  )
}

export default Cart
