import { useTranslation } from 'react-i18next'
import { useProductDetail } from '@ten24/slatwallreactlibrary/hooks'
import { useHistory, useLocation } from 'react-router'

// selection is an object of current paramters
// optionGroupPairs is an array of current paramters key=value
const SkuOptions = ({ productOptions, selection, skus = [] }) => {
  const { filterSkusBySelectedOptions, generateOptionGroupPair } = useProductDetail()
  const { t } = useTranslation()
  let loc = useLocation()
  let history = useHistory()
 ;

  // http://localhost:3006/product/test-product?colors=global-black&soccerBallColor=orange&soccerBallSize=3
  const selectedOption = (skus = [], optionGroupCode, optionCode, selection) => {
    const singlePair = generateOptionGroupPair(optionGroupCode, optionCode)
    selection[optionGroupCode] = optionCode
    const optionsToTest = Object.keys(selection).map(key => {
      return generateOptionGroupPair(key, selection[key])
    })
    const matchingSkus = filterSkusBySelectedOptions(skus, optionsToTest)

    if (matchingSkus.length === 1) {
      // http://localhost:3006/product/test-product?colors=global-black&soccerBallSize=3
      console.log('Single Matching sku')
      history.replace({
        pathname: loc.pathname,
        search: matchingSkus[0].slug,
      })
    } else if (matchingSkus.length === 0) {
      const possibleSKus = filterSkusBySelectedOptions(skus, [singlePair])
      if (possibleSKus.length === 1) {
        console.log('Single Matching sku')
        // http://localhost:3006/product/test-product?soccerBallColor=orange&colors=global-red&soccerBallSize=3  select 4
        history.replace({
          pathname: loc.pathname,
          search: possibleSKus[0].slug,
        })
      } else if (possibleSKus.length > 1) {
        console.log('The selection was not valid so we will reset option selection to current selection')
        // http://localhost:3006/product/test-product?soccerBallColor=yellow&colors=global-black&soccerBallSize=4 ==> select red
        history.replace({
          pathname: loc.pathname,
          search: singlePair,
        })
      }
    } else {
      console.log('Multiple remaining skus after new selection')
      // http://localhost:3006/product/test-product?soccerBallColor=orange&soccerBallSize=3 select orange
      history.replace({
        pathname: loc.pathname,
        search: optionsToTest.join('&'),
      })
    }
  }
  return (
    <>
      {productOptions.length > 0 &&
        productOptions.map(({ optionGroupName, options, optionGroupID, optionGroupCode }) => {
          const selectedOptionCode = selection[optionGroupCode] || 'select'
          return (
            <div className="form-group" key={optionGroupID}>
              <div className="d-flex justify-content-between align-items-center pb-1">
                <label className="font-weight-medium" htmlFor={optionGroupID}>
                {optionGroupName}
                </label>
              </div>
              { options.length === 1 ? <input className="form-control border-0 bg-0 p-0" type="text" defaultValue={options[0].optionCode} id={optionGroupID} readonly="readonly" style={{ height: 'auto', marginTop: '-10px' }}/> :
              <select
                className="custom-select"
                required
                value={selectedOptionCode}
                id={optionGroupID}
                onChange={e => {
                  selectedOption(skus, optionGroupCode, e.target.value, selection)
                }}
              >
                {selectedOptionCode === 'select' && (
                  <option className={`option nonactive`} value="select">
                    {t('frontend.product.select')}
                  </option>
                )}
                {options &&
                  options.map(option => {
                    return (
                      <option className={`option ${option.active ? 'active' : 'nonactive'}`} key={option.optionID} value={option.optionCode}>
                        {option.available && option.optionName}
                        {!option.available && option.optionName + ' - ' + t('frontend.product.na')}
                      </option>
                    )
                  })}
              </select>}
            </div>
          )
        })}
    </>
  )
}

export { SkuOptions }
